.header {
  position: sticky;
  top: 0;
  z-index: 20;
  background: #FFFFFF1A;
}

.container {
  display: flex;
  justify-content: space-around;
}

.headerInner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.headerInner svg {
  cursor: pointer;
}

.headerButtons {
  display: flex;
  margin-left: 110px;
  width: 722px;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}

.headerButtons button {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 200;
  cursor: pointer;
  transition: 0.2s all linear;
}

.headerButtonsContainer:last-child .contactsBtn {
  width: 138px;
}

.contactsBtn {
  border: none;
  width: 113px;
  background: inherit;
  color: #ffffff;
}

.contactsBtn:first-child {
  width: fit-content;
}

.contactsBtn svg {
  padding: 0 12px;
}

.joinBtn {
  border: none;
  border-radius: 40px;
  width: 200px;
  background: #AD1388;
}

.joinBtn a {
  display: block;
  color: #ffffff;
  padding: 8px 0;
}

.contactsBtn:hover {
  color: #A5A5A5;
}

.contactsBtn:focus {
  background: -webkit-linear-gradient(351deg, #C2465F 0%, #9501F5 100%);
  background-clip: unset;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.joinBtn:hover {
  background: #1D1665;
}

.joinBtn:focus {
  background: #1C1B27;
}

.language {
  /* margin-top: 4px; */
  background: #55325A;
  position: absolute;
  /* top: 85px; */
  right: 20.5%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 2px;
  border-radius: 5px;
}

.languageBtn {
  width: 142px;
  background: #55325A;
  padding: 8px 10px;
  gap: 10px;
  text-align: left;
  border: none;
  border-radius: 5px;
  color: #BEC8D6;
  line-height: 22px;
  letter-spacing: 0.10000000149011612px;
}

.languageBtn:focus {
  background: #AD1388;
}

.languageBtn:hover {
  background: #FFFFFF24;
}

.arrowBtn {
  transform: rotate(180deg);
  transition: transform 1s;
}

.arrowBtnOpen {
  transition: transform 1s;
}

.open {
  opacity: 1;
  transition: opacity 1s;
}

.close {
  opacity: 0;
  transition: opacity 1s;
}

.close.headerButtons, .close.headerButtonsModalContainer, .close.languageBtn, .close.language, .close.modalLanguageContainer {
  display: none;
}

.burgerBtn {
  display: none;
}

.modalLanguageContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 69px;
  left: 0;
}

@media (max-width: 1100px) {
  .headerInner > svg {
    width: 150px;
    margin-left: 17px;
  }

  .headerButtons {
    margin-left: 0;
  }

  .joinBtn {
    margin-right: 17px;
  }
}

@media (max-width: 860px) {
  .headerInner {
    justify-content: space-between;
    padding: 5px 0;
  }

  .headerInner svg {
    width: 120px;
    margin-left: 17px;
  }

  .headerButtonsModalContainer {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
  }

  .burgerBtn {
    display: flex;
    width: 26px;
    cursor: pointer;
    z-index: 1;
    background: none;
    border: none;
    margin-right: 17px;
  }

  .burgerBtn > span,
  .burgerBtn > span::before,
  .burgerBtn > span::after {
    position: absolute;
    width: 17.45px;
    height: 2.57px;
    border-radius: 40px;
    background: linear-gradient(90deg, #8176AF -15.79%, #C0B7E8 -15.78%, #8176AF 100%);
    display: flex;
    justify-content: flex-end;
  }

  .burgerBtn > span::before {
    content: '';
    top: -8px;
    width: 24px;
  }

  .burgerBtn > span::after {
    content: '';
    top: 8px;
    width: 24px;
  }

  .headerButtonsContainer {
    width: 90%;
  }

  .headerButtonsContainer:last-child {
    display: flex;
    flex-direction: column;
  }

  .language {
    position: static;
    background: none;
  }

  .languageBtn {
    background: none;
  }

  .headerButtonsContainer:last-child .contactsBtn, .contactsBtn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    border-radius: 5px;
    align-items: center;
  }

  .contactsBtn:focus {
    background: #FFFFFF1A;
    color: #ffffff;
    background-clip: unset;
    -webkit-background-clip: unset;
	  -webkit-text-fill-color: unset;
  }

  .contactsBtn svg{
    width: auto;
  }

  .joinBtn {
    display: none;
  }

  .headerButtons {
    display: flex;
    background: #230845;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    right: 0px;
    top: 48px;
    width: 62%;
    height: 800px;
    margin: 0;
    gap: 33px;
    transition: all 1s;
  }

}
