.footer {
  background: linear-gradient(90deg, #0D0A29 0%, #2C1B5C 100%);
  height: 520px;
  font-family: Poppins;
  color: #FFFFFF;
}

.topContainer {
  height: 423px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 102px;
}

.leftContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 58px;
}

.leftContainer svg{
  width: 320px;
  height: 65px;
}

.comingSoonContainer {
  height: 96px;
}

.comingSoonContainer p{
  padding-top: 10px;
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-align: center;
  text-transform: uppercase;
}

.comingSoonImg{
  display: flex;
  margin-top: 21px;
  width: 320px;
  height: 56px;
  gap: 20px;
}

.img {
  background: #FDFDFD38;
  width: 150px;
  height: 56px;
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightContainer {
  display: flex;
  margin-top: 92px;
  width: 586px;
  height: 255px;
  justify-content: space-between;
}

.navigationContainer:last-child{
  margin-right: 13px;
}

.socialNetworkContainer {
  margin-top: 25px;
}

.socialNetworkContainer h3 {
  display: none;
}

.navigationContainer h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 22px;
}

.navigationContainer p {
  font-size: 16px;
  font-weight: 100;
  line-height: 24px;
  text-align: left;
  margin: 7px 0;
  text-decoration: none;
  color: #E1DBED;
  cursor: pointer;
}

.navigationContainer:first-child {
  display: flex;
  justify-content: space-between;
  gap: 77px;
}

.navigationContainer:first-child .navigationContainer:first-child h3{
  margin-bottom: 15px;
}

.navigationContainer:first-child .navigationContainer:first-child p{
  margin: 4px 0;
}

.navigationContainer:first-child .navigationContainer:first-child {
  gap: 0;
  flex-direction: column;
  justify-content: flex-start;
}

.footer a {
  text-decoration: none;
  color: #E1DBED;
}

.bottomContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #FFFFFF33;
}

.rightsReserved p{
  color:#FFFFFF4D;
  font-size: 12px;
  font-weight: 200;
  line-height: 14px;
  text-align: center;
}

.rightsReserved {
  position: relative;
  left: 44%;
  bottom: -18px;
}

.iconsContainer {
  display: flex;
  justify-content: space-between;
  width: 198px;
  position: relative;
  right: 7%;
  bottom: -22px;
}

.contactIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FDFDFD38;
  width: 39px;
  height: 39px;
  border-radius: 39px;
}

@media (max-width: 1100px){
  .topContainer {
    margin: 0 35px;
    gap: 30px;
  }
}

@media (max-width: 880px) {
  .footer {
    background: none;
    height: auto;
  }

  .footer a{
   height: 15px;
  }

  .topContainer {
    flex-direction: column-reverse;
    justify-content: center;
    margin: 0;
    height: auto;
  }

  .rightContainer {
    margin-top: 28px;
    width: 80%;
    height: auto;
  }

  .navigationContainer:first-child {
    /* margin-right: 51px; */
    flex-direction: column;
    gap: 0;
  }

  .navigationContainer:first-child .navigationContainer {
    margin-right: 0;
  }

  .navigationContainer:first-child .navigationContainer:last-child {
    flex-direction: column;
  }

  .navigationContainer:last-child {
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  
  .navigationContainer h3{
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 7px !important;
  }
  
  .navigationContainer p{
    font-size: 14px;
    line-height: 21px;
    margin: 4px 0;
  }

  .navigationContainer p:last-child{
    margin-bottom: 23px;
  }

  .navigationContainer:last-child p:last-child {
    margin-bottom: 0;
  }

  .contactContainer{
    margin-top: 22px;
  }

  .contactContainer p{
    margin: 9px 0;
  }

  .contactContainer p:last-child {
    margin-bottom: 0;
  }

  .socialNetworkContainer {
    margin-top: 0;
  }

  .socialNetworkContainer p{
    margin: 9px 0;
  }

  .socialNetworkContainer h3 {
    display: inline;
  }
  
  .leftContainer svg {
    width: 198px;
    height: 40px;
}

  .bottomContainer {
    flex-direction: column-reverse;
    align-items: center;
    border-top: none;
  }

  .comingSoonContainer p {
    padding-top: 10px;
    padding-bottom: 3px;
    font-size: 10px;
  }

  .img {
    width: 125px;
    height: 47px;
    padding: 0;
  }

  .img img {
    height: 50%;
  }

  .contactIcon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }
  .contactIcon img{
    width: 13px;
    height: 13px;
  }

  .rightsReserved p{
    font-size: 8px;
  }

  .rightsReserved {
    bottom: 12px;
    left: 0;
  }

  .iconsContainer {
    justify-content: space-evenly;
    bottom: 180px;
    right: -28%;
    width: 170px;
  }

  .comingSoonImg {
    margin: 0;
    width: auto;
    height: auto;
  }
}

@media (max-width: 550px) {
  .rightContainer {
    width: 93%;
    gap: 10px;
  }

  .iconsContainer {
    bottom: 173px;
    right: -77px;
  }

  .leftContainer {
    margin-top: 63px;
    flex-direction: column-reverse;
  }

  .leftContainer svg {
    width: 136px;
    height: 27px;
  }
}