.aboutWrapper {
  padding-top: 148px;
  padding-bottom: 34px;
  /* Высота экрана - Хедер - отступ */
  height: calc(100vh - 92px - 90px);
  background: #00000080;

}

.aboutScreen {
  display: flex;
  justify-content: center;
}

.aboutScreenImage {
  margin-left: 20px;
}

.aboutScreenImage img {
  width: 470px;
  height: 500px;
}

.aboutContent {
  font-family: Poppins;
  width: 1134px;
  padding: 46px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  background: linear-gradient(90deg, #2C1B5C 3%, #3B2ECF 81.17%);
  background-size: cover;
  background-attachment: fixed;
}

.aboutText {
  width: 604px;
  margin-left: 40px;
}

.aboutText h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  color: #9990FF;
  margin-bottom: 40px;
}

.aboutText p {
  font-size: 18px;
  font-weight: 200;
  line-height: 27px;
  color: #EEEFF0;
  margin-bottom: 38px;
  text-align: justify;
}

.aboutText del {
  text-decoration: none;
}

.aboutText p:last-child {
  margin-top: 32px;
}

.centerAbout {
  display: none;
}

@media (max-width: 1200px) {
  .aboutContent{
    width: 900px;
    padding: 27px 0;
  }

  .aboutWrapper {
    padding-bottom: 65px;
  }

  .aboutScreenImage img {
    width: 379px;
    height: 570px;
  }
}

@media (max-width: 950px) { 
  .aboutWrapper {
    background: none;
    padding-top: 16px;
    height: auto;
    padding-bottom: 0;
  }

  .aboutContent {
    width: 95%;
    background: none;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    padding-top: 35px;
    overflow-x: hidden;
    border-radius: 0;
  }

  .aboutText {
    width: 70%;
    margin: 0;
    margin-top: 50px;
  }

  .aboutText h2 {
    text-align: center;
    color: #A692FF;
    margin-bottom: 37px;
    font-size: 30px;
    line-height: 45px;
  }

  .aboutText p {
    color: #EEEFF0;
    margin-bottom: 20px;
    text-align: justify;
    font-size: 16px;
    line-height: 21px;
  }

  .aboutText del {
    display: none;
  }

  .aboutScreenImage img {
    display: none;
  }

  .aboutSlide {
    width: 157.78px !important;
    padding: 10px;
  }

  .aboutSlide img {
    border-radius: 30px;
    width: 100%;
    height: 180.37px;
  }

  .centerAbout .slick-list {
    overflow: visible;
  }

  .centerAbout {
    display: block;
    height: 30%;
    width: 27%;
  }

  .centerAbout .slick-slide{
    visibility: hidden;
  }

  .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
    width: auto;
}

  .centerAbout .slick-current {
    transition: all 1s;
    z-index: 7;
    position: relative;
    top: -14px;
  }

  .slick-current .aboutSlide {
    height: 211.88px;
  }

  .centerAbout .slick-current .aboutSlide img{
    transition: all 1s;
    box-shadow: 3px 3px 34px 3px #FFFFFF47;
    height: 211.88px;
  }
}

@media (max-width: 550px) {
  .aboutText h2 {
    text-align: left;
    font-size: 30px;
    line-height: 45px;
  }

  .aboutText p {
    font-size: 14px;
    line-height: 21px;
  }


  .aboutText {
    width: 339px;
    margin-top: 36px;
  }
}
