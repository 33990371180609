.welcomeWrapper {
  padding-top: 135px;
  /* Высота экрана - Хедер - отступ */
  height: calc(100vh - 92px - 90px);
}

.welcomeScreen {
  display: flex;
  justify-content: center;
}

.welcomeContent {
  width: 1134px;
  height: 604px;
  display: flex;
  background: url(../../assets/images/background.jpg);
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  border-radius: 60px;
  position: absolute;
}

.welcomeText {
  width: 596px;
  height: 303px;
}

.welcomeDescription {
  width: 544px;
  margin-bottom: 29px;
}

.welcomeScreenImage img:first-child {
  width: 29%;
  position: absolute;
  left: 197px;
  top: -17px;
}

.welcomeScreenImage img:last-child {
  width: 27%;
  position: absolute;
  left: 23px;
  bottom: -46px;
}

.welcomeBtn {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 200;
  line-height: 27px;
  border-radius: 40px;
  cursor: pointer;
  transition: 0.2s all linear;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(190, 200, 214, 0.49) 0%, rgba(0, 0, 0, 0.4) 100%);
  width: 200px;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.35) -52%, rgba(35, 0, 255, 0.35) 100%);
}

.welcomeBtn a {
  display: block;
  color: #ffffff;
  padding: 11.5px 0;
}

.welcomeBtn:hover {
  background: #FFFFFF59;
}

.welcomeBtn:focus {
  background: linear-gradient(90deg, #C2465F 0%, #9501F5 100%);
}

.welcomeText p {
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #8176af;
}

.welcomeText p:last-child {
  font-size: 18px;
  font-weight: 100;
  line-height: 27px;
  color: #FFFFFF;
  text-transform: none;
  letter-spacing: normal;
}

.welcomeText h3 {
  font-family: "Botanica";
  font-size: 90px;
  font-weight: 200;
  line-height: 60px;
  letter-spacing: 0.08em;
  color: #ffffff;
  text-transform: uppercase;
  padding: 28px 0 49px 0;
}

.welcomeContentLinks a {
  margin-right: 38px;
}

@media (max-width: 1200px) {
  .welcomeWrapper {
    padding-top: 57px;
  }

  .welcomeContent {
    width: 900px;
  }

  .welcomeScreenImage img:first-child {
    width: 35%;
    left: 73px;
    top: -62px;
  }

  .welcomeScreenImage img:last-child {
    width: 35%;
    left: 0px;
    bottom: -72px;
  }

  .welcomeText {
    width: 515px;
  }

  .welcomeDescription {
    width: 455px;
  }
}

@media (max-width: 950px) {
  .welcomeWrapper{
    height: 90vh;
    position: relative;
    top: -88px;
    padding-top: 215px;
    background: url(../../assets/images/background-mobile-welcome.png);
    background-size: cover;
    background-position: bottom;
  }

  .welcomeContent{
    flex-direction: column;
    justify-content: flex-end;
    background: #FFFFFF1A;
    width: 339px;
    height: 248px;
    margin-top: -47px;
  }

  .welcomeScreenImage img:first-child {
    width: 53%;
    left: 140px;
    top: -4px;
    z-index: 1;
  }

  .welcomeScreenImage img:last-child {
    width: 62%;
    left: 21px;
    bottom: -55px;
  }

  .welcomeText {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 318px;
  }

  .welcomeDescription{
    width: auto;
    margin-bottom: 37px;
  }

  .welcomeText h3 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 62px;
    line-height: 50px;
    padding: 15px 0 37px 0;
  }

  .welcomeText p {
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 18px;
    color: #A59DFF;
  }

  .welcomeText p:last-child {
    font-size: 16px;
    font-weight: 200;
    line-height: 24px;
    text-align: center;
  }

  .welcomeBtn {
    width: 339px;
    background: #9935CD;
    border: 1px solid #BEC8D636;
    color: #D5D2FF;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 650px) {
  .welcomeText {
    width: auto;
  }
}