.cocktailsWrapper {
  padding-top: 130px;
  /* Высота экрана - Хедер - отступ */
  /* height: calc(100vh - 92px - 90px); */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centerCocktails {
  width: 1180px;
}

.centerCocktails .slick-track {
  display: flex;
  gap: 20px;
}

.cocktailCard {
  height: 604px;
  width: 1134px;
  display: flex;
  justify-content: flex-end;
  background: url(../../assets/images/background.jpg);
  background-size: cover;
  background-attachment: fixed;
  border-radius: 60px;
  /* margin: 0 10px */
}

.centerCocktails .slick-dots li {
  margin: 0 2px !important;
}

.centerCocktails .dots {
  margin-top: 17px;
  width: 10px;
  height: 10px;
  background: #822069;
  border-radius: 10px;
}

.centerCocktails .slick-active .dots {
  background:#FF01BF;
}

.cocktailCard img {
  width: 567px;
  object-fit: cover;
  border-radius: 0 60px 60px 0;
}

.cardText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 14px 46px;
}

.cardText p {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  color: #BEC8D6;
  text-transform: uppercase;
}

.cardText h3 {
  font-size: 72px;
  font-weight: 200;
  line-height: 90.24px;
  letter-spacing: 0.11em;
  text-align: left;
  font-family: "Botanica";
  color: #FF01C0;
  text-transform: uppercase;
}

.cardTags {
  display: flex;
  margin: 15px 0 0 -4px;
}

.cardTags p {
  display: block;
  width: fit-content;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 22.4px;
  color: #BEC8D6;
  text-transform: none;
  border: 1px solid #BEC8D6;
  padding: 5.9px 11.8px;
  border-radius: 19.17px;
  margin: 0 4px;
}

.cardDescription {
  width: 463px;
  text-align: justify;
  margin-top: 43px;
}

.cardDescription p {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 100;
  line-height: 27px;
  text-transform: none;
}

.iconContainer {
  background: #00000040;
  display: flex;
  margin-top: 145px;
  width: 100%;
  padding: 43px 0;
  justify-content: space-evenly;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.icon:first-child {
  margin-left: 70px;
}

.icon:last-child {
  margin-right: 70px;
}

.icon p {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  color: #EEEFF0;
  text-transform: uppercase;
}

.gradient {
  display: none;
}

@media (max-width: 1200px) {
  .cocktailCard {
    width: 900px;
  }

  .centerCocktails {
    width: 950px;
  }

  .cocktailCard img {
    width: 363px;
  }

  .cardText {
    padding: 0 0 14px 30px;
  }

  .icon p {
    text-align: center;
  }
}

@media (max-width: 950px) {
  .cocktailsWrapper {
    padding-top: 40px;
  }

  .centerCocktails {
    width: 525px;
  }

  .centerCocktails .slick-list {
    overflow: hidden;
  }

  .cocktailCard {
    height: fit-content;
    width: 500px;
    flex-direction: column-reverse;
    background: #00000040;
    border-radius: 30px;
  }

  .cocktailCard img {
    width: 100%;
    height: 288px;
    border-radius: 30px 30px 0 0;
  }

  .cardText {
    padding: 0 0 0px 28px;
    position: relative;
    bottom: 100px;
    z-index: 5;
    height: 255px;
  }

  .cardText h3 {
    font-size: 42px;
    line-height: 42.12px;
    color: #FFFFFF;
    margin-top: 14px;
  }

  .gradient {
    display: block;
    position: absolute;
    width: 500px;
    height: 171px;
    top: 117px;
    background: linear-gradient(360deg, #350F5E 11.9%, rgba(41, 12, 75, 0) 89.04%);
  }

  .cardDescription {
    width: 91%;
    margin-top: 40px;
  }

  .cardDescription p {
    font-size: 16px;
    line-height: 20px;
  }

  .cardTags {
    margin: 17px 0 0 -3px;
    flex-wrap: wrap;
  }

  .cardTags p {
    font-weight: 200;
    color: #FF01BF;
    border-color: #FF01BF;
    padding: 5px 11.5px;
    margin-bottom: 8px;
  }

  .dots {
    margin-top: 5px;
  }
}

@media (max-width: 850px) {
  .cocktailsWrapper {
    padding-top: 0;
  }

  .cardText h3 {
    font-size: 36px;
    line-height: 42.12px;
    color: #FFFFFF;
    margin-top: 14px;
  }

  .iconContainer {
    margin-top: 72px;
  }

  .icon {
    width: 25%;
  }

  .icon p {
    font-size: 14px;
    line-height: 21px;
  }

  .icoImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 18px;
  }

  .icon:first-child .icoImg img {
    width: 85px;
    height: 107px;
}

  .icoImg img {
    width: 78px;
    height: 90px;
}
}

@media (max-width: 550px) {

  .centerCocktails {
    width: 365px;
  }

  .cocktailCard, .gradient {
    width: 339px;
  }

  .cardDescription p {
    font-size: 12px;
    line-height: 18px;
  }

  .icon:first-child {
    margin-left: 20px;
  }

  .icon:last-child {
    margin-right: 20px;
  }

  .iconContainer {
    flex-direction: column;
    padding: 16px 0;
    background: #230845;
  }

  .icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;
    width: 99%;
  }

  .icoImg {
    background: #FFFFFF17;
    border-radius: 94px;
    width: 94px;
    height: 94px;
  }

  .icon:first-child .icoImg img {
    width: 85px;
    height: 85px;
  }

  .icoImg img {
    margin-top: 7px;
    width: 59px;
    height: 74px;
  }

  .icon:first-child {
    margin-left: 20px;
  }

  .icon:last-child {
    margin-right: 20px;
  }

  .icon p {
    text-align: left;
  }

  .icon:first-child {
    margin-left: 0;
  }

  .icon:last-child {
    margin-right: 0;
  }

  .icon:first-child .icoImg{
    justify-content: end;
  }
}

@media (max-width: 380px) {
  .cocktailsWrapper {
    align-items: flex-start;
  }
}