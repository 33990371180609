.appWrapper {
  background: url(assets/images/background.jpg);
  background-size: cover;
  background-attachment: fixed;
}

.colorContainer {
  background: linear-gradient(90deg, #2C1B5C 3%, #3B2ECF 81.17%);
  position: relative;
  top: -21px;
}

.container {
  display: block;
  background: #00000080;
}

/* .container {
  max-width: 1233px;
  padding: 0 24px;
  margin: 0 auto;
} */

@media (max-width: 950px) {
  .appWrapper {
    background: url(assets/images/background-mobile.png);
    background-size: cover;
    background-position: bottom;
  }

  .container {
    background: none;
  }

  .colorContainer {
    background: none;
    top: -55px;
  }
}
